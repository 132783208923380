
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$monitor-app-primary: mat.define-palette(mat.$indigo-palette, 400);
$monitor-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$monitor-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$monitor-app-theme: mat.define-light-theme((
  color: (
    primary: $monitor-app-primary,
    accent: $monitor-app-accent,
    warn: $monitor-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($monitor-app-theme);

@import '~bs-stepper/dist/css/bs-stepper.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'src/themes/design-system.scss';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

body, html {
    margin: 0;
    padding: 0;
    background: $main-bg-color;
    color: $theme-dark-23;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Open Sans",sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
	background: linear-gradient(35deg, rgba(255,255,255,1) 0%, rgba(24,57,180,0.1) 100%) fixed;
}

.item-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px 0 1px 5px !important;
    margin-bottom: 0;
}

.content {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}
.container-fluid, .container-xxl {
    padding-right: 2rem;
    padding-left: 2rem;
}
.container, .container-fluid, .container-xxl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.page-header {
    margin-bottom: 46px;
}
.page-header-title {
  font-weight: 600;
  font-size: 25px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: $theme-dark-23;

  .nested-title {
    color: $theme-medium-55;
    margin-right: 5px;
    font-weight: 500;
  }
}
.page-header-subtitle {
    font-size: 16px;
    line-height: 170%;
    color: $theme-dark-23;
    margin-top: 5px;
}
.page-header-subtitle {
    font-size: 16px;
    line-height: 170%;
    color: $theme-dark-23;
    margin-top: 5px;
}
.advancedFilter {
    padding-top: 18px;
}
.success {
    color: $success;
}
.warning{
    color:$warning;
}
.danger{
    color: $error;
}

.label-outline{
    padding: 3px 10px;
    line-height: 13px;
    color: #fff;
    font-weight: 400;
    border-radius: 2px;
    font-size: 75%;
    border: 1px solid transparent;
    background-color: transparent;
}

img.small-img{
    height: 25px;
}
a:focus,
button:focus,
textarea:focus, i:focus,
a:active, button:active {
    outline: none;
    outline: 0;
    box-shadow: none !important;
}
.card-title{
    font-size: 11px;
    margin-bottom: 2px;
    display: block;
}
.modal-header{
    border: none;
}
.hide{
    display: none!important;
}
.no-resize{
    resize: none;
}
.center{
    text-align: center;
}
.center-margin{
    margin-left: auto;
    margin-right: auto;
}
.text-right{
    text-align: right;
}
.pointer{
    cursor: pointer;
}
.nowrap{
    white-space: nowrap;
}
.br-50{
    border-radius: 50%;
}
.btn.btn-outline-primary.btn-icon-muted{
    border-color: $theme-light-86;
    color: $theme-medium-55;
}
.btn.btn-outline-primary.btn-icon-muted:hover{
    background-color: $theme-light-99;
}
.accordion .card-header{
    border: none;
    padding-bottom: 25px;
}
.legend-indicator{
    display: inline-block;
    width: .5rem;
    height: .5rem;
    background-color: #bdc5d1;
    border-radius: 50%;
    margin-right: .4375rem;
}
.badge.badge-soft{
    font-size: 10px!important;
}
.badge-soft-success {
    color: #00c9a7;
    background-color: rgba(0,201,167,.1);
}
.badge-soft-warning {
	color: #f7b924;
	background-color: rgba(247,185,36,.1);
}
.badge.badge-soft-danger {
    color: #ed4c78;
    background-color: rgba(237,76,120,.1);
}
.badge.badge-soft-info {
	color: #5ab3d7;
	background-color: rgba(#5ab3d7,.1);
}
.scrollable-card{
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}
ng-select::ng-deep.is-invalid .ng-select-container {
    border-color: #dc3545;
}

@media (max-width: 767px) {
    .mt-xs-5 { margin-top: 5px; }
    .mt-xs-10 { margin-top: 10px; }
    .mr-xs-5 { margin-right: 5px; }
    .mr-xs-10 { margin-right: 10px; }
    .mb-xs-5 { margin-bottom: 5px; }
    .mb-xs-10 { margin-bottom: 10px; }
    .ml-xs-5 { margin-left: 5px; }
    .ml-xs-10 { margin-left: 10px; }
}
.dataTables_filter{
    display: none!important;
}
// BS STEPPER OVERRIDE
.step.active button{
    color: #377dff;
}
.bs-stepper-label{
    font-size: 14px;
    font-weight: 400;
}
.bs-stepper-content {
    padding: 0;
}

//NG SELECT OVERRIDE
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    padding-bottom: 5px;
    padding-left: 3px;
	position: absolute !important;
	left: 30px;
	top: 7px;
}

.ng-select.no-icon.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	left: 10px;
}

//BOOTSTRAP OVERRIDE
.modal-body {
    padding: 18px!important;
}
.badge{
    font-size: inherit!important;
}
.accordion > .card {
    overflow: hidden;
    border-left: none;
    border-right: none;
    background: none;
}
.accordion > .card:first-child {
    border-top: none;
}

.accordion.last-accordion > .card {
    border-bottom: none;
}

.accordion > .card > .card-header {
    background: white;
}
i.icon-small{
    font-size: 10px;
}
.z-index-5 {
    z-index: 5;
}
.card-header-title {
    color: #1e2022;
    padding-left: 10px;
}

.btn-14 {
  font-size: 14px;
}

.stepper-card {
    & .modal-dialog {
        margin: 0 !important;
        & .modal-content {
            border: none;
            width: 100vw;
            height: 100vh;
        }
    }
}

.searchModal {
	.modal-dialog-centered {
		display: flex;
		justify-content: center;
	}
}

.attendance-quality {
	g.line-chart > g:last-of-type > g:nth-child(n) g.line-series > path {
		stroke: #FF00FF;
	}
}

.card-custom {
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.interaction-modal  {
	.modal-dialog {
		max-width: calc(100vw - 100px);
		height: calc(100vh - 100px);

		.modal-content {
			// overflow-y: auto;
			// height: calc(100vh - 70px);
		}
	}
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.custom-button {
	display: flex;
	justify-content: center;
	align-items: center;

	background: none;
	border-color: transparent;
	color: #32313b;
	font-weight: 600;
	width: 34px;
	height: 34px;

	cursor: pointer;
}

.custom-button.disabled {
	background: #f4f4f6;
	color: #aaa;
}

.evo-table {
	width: 100%;

	tr.mat-header-row {
		height: 36px;
	}

	.mat-row:hover {
		.mat-cell {
			background-color: rgba(#000, 0.1);
		}
	}

	.mat-cell {
		font-size: 12px;
	}

	.mat-column-sentiment {
		width: 92px;
	}

	.mat-column-action {
		width: 90px;
	}
}

:host ::ng-deep.mat-paginator {

	.mat-paginator-container {
		justify-content: space-between;
	}

	.mat-paginator-range-actions {
		width: 50%;
		justify-content: flex-end;
	}
}

.table-loading {
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 60px;

	width: 95%;
	height: 100px;
}

.badge-automatic {
	color: #333;
	background-color: #CCC;
}

.badge-manual {
	color: #7532ea;
	background-color: #e8e3ee;
}

.evo-tabs {
	display: flex;
	align-items: center;

	margin-top: 30px;
	margin-bottom: 20px;

	.tab {
		display: flex;
		justify-content: center;

		font-size: 14px;

		width: 230px;
		padding: 4px;
		border-bottom: 1px solid #DEDEDE;

		cursor: pointer;

		transition: 0.5s;

		&.active {
			border-bottom: 1px solid #1839B4;
		}
	}
}

.mat-tab-body-content {
	height: calc(100vh - 300px) !important;
}

.main-app-body {
	background-image: url('/assets/images/background-bottom.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	background-attachment: fixed;
}
