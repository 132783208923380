.evo-product {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	background-color: #dfdfdf;
	background-image: url("/assets/images/evollo_logo.svg");
	background-position: center center;
	background-size: 32px;

	border-radius: 5px;

	&.QUALITY {
		background-color: $primary;
	}

	&.TRAINING {
		background-color: $purple;
	}
}
