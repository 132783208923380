.dashboard {

	h5 {
		font-size: 20px;
		font-weight: 600;

		i {
			color: #727272;
		}
	}

	.subtitle {
		font-size: 16px;
		font-weight: 600;
	}

	.interaction-card {
		width: 100%;
		height: 150px;
		background-color: #fafafa;

		border: 1px solid #ECECEC;
		filter: drop-shadow(0px 6px 10px rgba(7, 13, 44, 0.03));
		border-radius: 4px;

		padding: 16px 16px 40px 16px;


		h3 {
			color: #000;
			font-size: 31px;
			font-weight: 600;
			margin-bottom: 16px;
		}

		span {
			color: #727272;
			font-size: 16px;
			font-weight: 600;
		}
	}

	.dashboard-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 16px;

		width: 100%;
		height: 280px;
		background-color: #fff;
		border: 1px solid #DEDEDE;
		border-radius: 12px;
		margin-right: 16px;
		transition: 0.5s;
		cursor: pointer;

		&.disabled {
			opacity: 60%;

			&:hover {
				opacity: 70%;
				transform: none;
				box-shadow: none;
				cursor: initial;
			}
		}

		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 5px 10px rgba(#000, 0.2);
		}

		.title {
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 16px;
		}

		.chart {
			display: flex;
			justify-content: center;
			background-color: #FAFAFA;
			width: 100%;
			margin-bottom: 16px;

			img {
				height: 124px;
				max-width: 100%;
			}
		}

		.legend {
			width: 100%;
			color: #5E5E5E;
			font-size: 14px;
		}

		.soon {
			width: 100%;
			margin-top: auto;
			display: flex;
			justify-content: flex-end;

			font-size: 14px;
			color: #9B9B9B;
		}
	}

	.preview-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 16px;

		// width: 256px;
		height: 256px;
		border: 1px solid #DEDEDE;
		border-radius: 12px;
		margin-right: 16px;

		.preview-title {
			width: 50%;
			height: 14px;
			background: #DEDEDE;
			border-radius: 4px;
		}

		.preview-content {
			width: 100%;
			height: 14px;
			background: #ECECEC;
			border-radius: 4px;
			margin-bottom: 8px;

			&.w-75 { width: 75%; }
			&.w-50 { width: 50%; }
			&.w-25 { width: 25%; }
		}
	}

	.chart-container {
		width: 100%;
		height: 100%;
		&.center {
			display: flex;
			justify-content: center;
		}
	}
}

.chart-legend .legend-wrap {
	width: 100% !important;
}

.filters {
	.filters-item {
		height: 62px;
	}
}
