::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background: #999;
	border: none;
	border-radius: 50px;
}

::-webkit-scrollbar-track {
	background: #eaeaea;
	border: none;
	border-radius: 50px;
}

::-webkit-scrollbar-corner {
	background: transparent;
}
