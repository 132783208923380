.form-control {
    border-color: $theme-light-86;

    &:focus {
        border-color: $primary;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(24, 57, 180, 0.5);
    }

	&:disabled {
		background-color: #f9f9f9;
	}

    &.ng-invalid:not(.ng-untouched) {
        border-color: $error-dark;
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times-circle' class='svg-inline--fa fa-times-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23911A1A' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z'%3E%3C/path%3E%3C/svg%3E");
        box-shadow: none;
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    &.form-control-sm {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.form-control-lg {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.form-group {
    padding-bottom: 1.5rem;
    & label {
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 180%;
        color: $theme-medium-38;
    }

    & label.required:after {
        content: '*';
    }

    & label.ng-invalid {
        color: $error-dark;
    }

    & .invalid-feedback {
        color: $error-dark;
    }
}

.custom-checkbox .custom-control-input, .custom-radio .custom-control-input {
    &~.custom-control-label::before {
        border-color: $primary;
        border-width: 2px;
    }

    &:checked~.custom-control-label::before {
        background-color: $primary;
    }

    &:checked~.custom-control-label::after {
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
    }

    &:disabled~.custom-control-label::before {
        border-color: $theme-medium-55;
        background-color: $white;
    }

    &:disabled:checked~.custom-control-label::before {
        background-color: $theme-medium-55;
    }

    &:focus~.custom-control-label::before {
        border-color: $primary;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(24, 57, 180, 0.5);
    }
}

.ngx-slider {
    margin-bottom: 30px;
    & .ngx-slider-bar-wrapper .ngx-slider-bar {
        background-color: $primary-light-10;
    }

    & .ngx-slider-bar-wrapper .ngx-slider-selection {
        background-color: $primary;
    }

    & .ngx-slider-pointer {
        appearance: none !important;
        width: 0 !important;
        height: 0 !important;
    }

    & .ngx-slider-pointer::after {
        background: $primary !important;
        border-radius: 5px !important;
        left: -2px !important;
        top: 11px !important;
        width: 10px !important;
        height: 10px !important;
    }

    & .ngx-slider-bubble:not(.ngx-slider-limit) {
        background-color: $primary;
        color: $white;
        border-radius: 4px;
        font-weight: 600;
        padding: 0 8px;
    }

    & .ngx-slider-bubble:not(.ngx-slider-limit):after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 4px);
        margin-bottom: -4px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $primary;
    }
}

.ng-select .btn {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
}

// Dropdown
.dropdown-menu {
    color: $theme-dark-23;
    border: 1px solid #F4F4F6;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 25px 35px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 0;

    .dropdown-item {
        padding: 0.5rem 1rem;

        > i {
            margin-right: 10px;
        }
    }
}
