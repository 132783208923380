/*
 * Buttons
 * */

.btn {
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 16px;

  &:not(button[class*=" btn-"]) {
    color: $theme-medium-55;
  }

  &.btn-sm {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 170%;
  }
  &.btn-lg {
    padding: 10.5px 16px;
  }
}
.btn-primary {
  font-weight: 600;
  letter-spacing: -0.5px;

  &:hover,
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-dark;
    border-color: $primary-dark;
  }
  &:disabled {
    background-color: $theme-medium-55;
    border-color: $theme-medium-55;
  }
}
.btn-outline-primary {
  &:hover,
  &:not(:disabled):not(.disabled):active {
    color: $primary;
    background-color: $primary-light-10;
    border-color: $primary;
  }
  &:disabled {
    background-color: $theme-light-99;
    border-color: $theme-light-86;
    color: $theme-medium-55;
  }
}
.btn-light {
  background-color: #FFF;
  border-color: #FFF;
  color: #76738C;

  &:hover,
  &:not(:disabled):not(.disabled):active {
    background-color: $theme-light-96;
    border-color: $theme-light-96;
  }
  &:disabled {
    background-color: #FFF;
    border-color: #FFF;
    color: $theme-light-86;
  }
}
.btn-add {
	color: #1839B4;
	font-size: 14px;

	background: none;
	border: none;

	margin-top: 16px;

	&.large {
		font-weight: 600;
		font-size: 16px;
	}

	&:hover {
		color: darken(#1839b4, 10%);
	}

	&.disabled {
		color: $theme-light-86;
	}
}

.btn-remove {
  color: #1839B4;
  font-size: 12px;

  background: none;
  border: none;

  margin-bottom: 0.5em;

  &:hover {
		color: darken(#1839b4, 10%);
	}

	&.disabled {
		color: $theme-light-86;
	}
}
