.card {
	padding: 32px;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		border-bottom: 1px solid #dedede;
		padding-bottom: 20px;
	}

	.organization-info {
		display: flex;
		flex-direction: column;
		align-items: center;

		width: 100%;
		padding: 16px 0;

		.organization-logo {
			width: 98px;
			height: 98px;
			border-radius: 50px;
			overflow: hidden;

			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;

			&.no-logo {
				background-color: #4BECCE;
				color: #1B2124;

				display: flex;
				justify-content: center;
				align-items: center;

				i {
					font-size: 40px;
				}

				h1 {
					margin: 0;
				}
			}
		}
	}
}
