.first-steps {
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;

	h4.title {
		font-size: 25px;
		font-weight: 600;
		margin-bottom: 16px;
	}

	p.description {
		font-size: 16px;
		margin-bottom: 32px;
	}

	p.sub-description {
		font-size: 14px;
	}

	.img-legend {
		font-size: 16px;
		font-weight: 600;
	}
}

.checklist-segment-header {
	span:first-child {
		font-size: 16px;
		margin-right: 8px;
		color: #000;
	}

	span {
		font-size: 14px;
		font-weight: 600;
		color: #5E5E5E;
	}

	h4 {
		text-align: center;
		margin-top: 50px;
		font-weight: 600;
		font-size: 25px;
	}

	p {
		color: #5E5E5E;
		text-align: center;
	}
}

.checklist-segment {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.checklist-item-group {
	min-width: 700px;
	background-color: #FAFAFA;
	padding: 24px;

	.checklist-item-header {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 16px;

		.info {
			width: 100%;
		}
	}

	.checklist-item {
		width: 100%;
		background-color: #fff;
		border: 1px solid #ECECEC;
		padding: 16px;

		&.first {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&.last {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		.item-row {
			width: 100%;
			display: flex;
			margin-bottom: 8px;
		}

		.name, .item-name {
			width: 100%;
			margin-right: 16px;
		}

		.item-descricao {
			width: 100%;
		}

		.weight, .item-peso {
			width: 100px;
			margin-right: 16px;
		}

		.ncg, .item-ncg {
			width: 80px;
			margin-right: 16px;
		}

		.action, .item-action {
			display: flex;
			align-items: center;
			height: 20px;

			.btn-toggle:first-child {
				margin-right: 27px;
			}

			.btn-delete, .btn-toggle {
				display: flex;
				justify-content: center;
				align-items: center;

				font-size: 16px;

				width: 24px;
				height: 24px;

				cursor: pointer;
			}
		}
	}

	.header-action {
		margin-left: 16px;
		padding: 8px 5px;
		cursor: pointer;
		font-size: 18px;
	}
}

