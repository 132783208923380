@import "theme.scss";

$success: #76D489;
$neutral: #E6BB67;
$danger: #DF7373;
$grade: #722FC5;

$calldrop1: #3808A4;
$calldrop2: #722FC5;
$calldrop3: #9D71D7;

.chart-card {
	width: 100%;
	height: 100%;
	border: 1px solid #DEDEDE;
	background: #fff;
	border-radius: 8px;
	position: relative;
	z-index: 1;
	transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);

	.chart-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #DEDEDE;
		padding: 18px 16px;

		.title {
			font-size: 20px;
			font-weight: 600;
		}
	}

	.chart-body {
		position: absolute;
		top: 69px;
		bottom: 0;
		width: 100%;
		padding-top: 32px;
	}

	.chart-actions {
		display: flex;

		i {
			font-size: 16px;
			margin-left: 15px;
		}

		.drag-handle {
			cursor: move;
		}
	}
}

.progress-cards {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 100%;

	.progress-card {
		position: relative;
		flex: 1;
		height: 225px;
		background-color: #FAFAFA;
		margin: 0 8px;

		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		overflow: hidden;

		&.progress-card-success {
			border-bottom: 8px solid $success;

			.progress-card-background {
				background-color: rgba($success, 0.2);
			}
		}

		&.progress-card-neutral {
			border-bottom: 8px solid $neutral;

			.progress-card-background {
				background-color: rgba($neutral, 0.2);
			}
		}

		&.progress-card-danger {
			border-bottom: 8px solid $danger;

			.progress-card-background {
				background-color: rgba($danger, 0.2);
			}
		}

		&.progress-card-grade {
			border-bottom: 8px solid $grade;

			.progress-card-background {
				background-color: rgba($grade, 0.2);
			}
		}

		&.progress-card-callback-1 {
			border-bottom: 8px solid $calldrop1;

			.progress-card-background {
				background-color: rgba($calldrop1, 0.2);
			}
		}

		&.progress-card-callback-2 {
			border-bottom: 8px solid $calldrop2;

			.progress-card-background {
				background-color: rgba($calldrop2, 0.2);
			}
		}

		&.progress-card-callback-3 {
			border-bottom: 8px solid $calldrop3;

			.progress-card-background {
				background-color: rgba($calldrop3, 0.2);
			}
		}

		.progress-card-background {
			width: 100%;
			position: absolute;
			bottom: 0;
			transition: 0.5s;
		}

		.progress-card-content {
			position: absolute;
			width: 100%;
			height: 100%;

			padding: 26px;

			display: flex;
			flex-direction: column;
			justify-content: center;

			h3 {
				font-size: 31px;
				font-weight: 600;
			}
		}

	}
}
