/*
 * Navigation tabs
 * */

.nav-tabs {
    border-bottom: 1px solid $theme-light-86;
}
.nav-item > .nav-link {
    font-size: 16px;
    color: $theme-medium-55;
    border: none;
    border-bottom: 1px solid $theme-light-86;

    &.active {
        color: $primary;
        font-weight: bold;
        border-bottom-color: $primary;
    }
    &:hover:not(.active) {
        color: $theme-medium-38;
    }
    &.disabled {
        color: $theme-light-86;
    }
}
