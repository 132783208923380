/*
 * Table & Pagination
 * */

.evo-table {
  color: $theme-medium-38;
  border-collapse: collapse;

  thead {
	  th.mat-header-cell:last-of-type {
		  padding-right: 24px !important;
	  }

    th {
      background-color: $theme-light-96;
      border: none;
      font-weight: 400;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 12px 9px !important;
      font-size: 10px;

      // Sorting styles
      .desc_icon { display: none; }
      .asc_icon { color: #CCC; } // $theme-medium-55
      &.sorting {
        background-image: none;
      }
      &.sorting_asc {
        background-image: none;
        font-weight: 600;
        .desc_icon { display: none; }
        .asc_icon { color: $primary; }
      }
      &.sorting_desc {
        background-image: none;
        font-weight: 600;
        .desc_icon { color: $primary; display: inline; }
        .asc_icon { display: none; }
      }
    }
  }
  tbody {
    td {
      padding: 9px 9px;
      border-top: none;
      border-bottom: 1px solid $theme-light-96;
    }
  }
}

data-table {
  .dataTables_info {
    position: absolute;
    margin-top: 12px;
  }
  .dataTables_wrapper .dataTables_paginate {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  table.dataTable thead th,
  table.dataTable thead td,
  table.dataTable.no-footer {
    border: none;
  }
  // buttons
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: $theme-dark-23;

    &:hover {
      background: $theme-light-96;
      border-color: transparent;
      color: $theme-dark-23 !important;
    }
    &.current,
    &.current:hover {
      background: $theme-light-96;
      border-color: transparent;
      color: $theme-dark-23;
      font-weight: 600;
    }

    &.previous.disabled, &.next.disabled {
      color: #bababa !important;
    }
  }
}
// Hotfix for data-tables with inner-pagination
.custom-pagination .dataTables_empty {
  display: none;
}
