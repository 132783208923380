@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Legacy variables:
$main-bg-color: #fff;
:root {
  --main-font-color: #132144;
  --light-blue: #17a2b8;
}

// Sizes
$topNavbarHeight: 44px;

// System colors
$error: #E52829;
$error-dark: #911A1A;
$error-light: #FBE9E9;
$warning: #F8C648;
$warning-dark: #5E470C;
$warning-light: #FCF1D4;
$success: #52C41A;
$success-dark: #3D5E0D;
$success-light: #F3FBEA;
$info: #19B7E8;
$info-dark: #105063;
$info-light: #E6F5FA;
$light-blue: #add8e6;

// Evollo theme
$primary: #1737af;
$primary-dark: #000046;
$primary-light-10: #E8EAF4;
$primary-light-50: rgba(24, 57, 180, 0.5);
$secondary: #4BECCE;
$secondary-light: #87FAE4;
$secondary-light-10: rgba(75, 236, 206, 0.1);
$secondary-light-50: rgba(75, 236, 206, 0.5);
$theme-black: #18171F;
$theme-font-grey: #1B2124;
$theme-dark-23: #32313B;
$theme-medium-38: #504E60;
$theme-medium-55: #76738C;
$theme-light-86: #D6D6DC;
$theme-light-96: #F4F4F6;
$theme-light-99: #F9F9FB;

// Bootstrap Overrides
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $error,
  "light": $theme-light-86,
  "dark": $theme-dark-23,
);
