/*
 * Snackbar & Toasts
 * */

.ngb-toasts {
  .toast {
    width: 344px;
    border: none;
    background-color: $theme-dark-23;
    color: $theme-light-99;
  }
  .toast-body {
    display: flex;
    padding: 10px 16px;

    .toast-template {
      flex: 1;
      font-size: 16px;
      line-height: 170%;
    }
    .toast-actions {
      display: flex;
      font-size: 12px;
      color: $theme-light-86;
      align-self: center;

      .undo-button {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
      .close-button {
        cursor: pointer;
        margin-left: 10px;
        width: 20px;
        text-align: center;
      }
    }
  }
  // Colors
  .toast-success {
    background-color: $success-dark;
  }
  .toast-warning {
    background-color: $warning-dark;
  }
  .toast-danger {
    background-color: $error-dark;
  }
  .toast-light {
    background-color: $theme-light-99;
    color: $theme-dark-23;

    .toast-actions {
      color: $theme-medium-55;
    }
  }
}
