@import "theme.scss";

* {
    font-family: 'Open Sans';
}

h1 {
    font-size: 48px;
    line-height: 110%;
    color: $theme-black;
}

h2, h3, h4, h5, h6 {
    color: $theme-font-grey;
}

h2 {
    font-size: 39px;
    line-height: 115%;
}

h3 {
    font-size: 31px;
    line-height: 125%;
}

h4 {
    font-size: 25px;
    line-height: 130%;
}

h5 {
    font-size: 20px;
    line-height: 140%;
}

h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
}