/*
 * Status/Badges & Chips
 * */

// Badges
.badge-primary {
  background-color: $primary-light-10;
  color: $primary-dark;
}
.badge-success {
  background-color: $success-light;
  color: $success-dark;
}
.badge-danger {
  background-color: $error-light;
  color: $error-dark;
}
.badge-warning {
  background-color: $warning-light;
  color: $warning-dark;
}
.badge-light {
  background-color: $theme-light-96;
  color: $theme-dark-23;
}
